import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{staticClass:"elevation-0",staticStyle:{"cursor":"pointer"},on:{"click":_vm.clickLogo}},[_c('img',{attrs:{"alt":"FulWMS logo","src":_vm.logoSrc,"width":_vm.$vuetify.breakpoint.mdAndUp ? '160px' : '140px'}})]),_c('div',[_c('Search',{staticClass:"ma-3"}),_c(VDivider)],1),_vm._l((_vm.filteredItems),function(section,index){return _c('div',{key:index,staticClass:"mb-2"},[(index !== 0)?_c(VDivider):_vm._e(),_c(VSubheader,[_vm._v(" "+_vm._s(_vm.$t(section.header))+" "),_c(VSpacer)],1),_c(VList,_vm._l((section.items),function(item){return _c(VListItem,{key:item.to,class:item.submenu ? 'text-body-2 ml-4 mt-n2' : '',style:(item.submenu ? '' : 'z-index: 5'),attrs:{"to":item.to,"exact":""}},[_c(VListItemAction,[_c(VIcon,[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c(VListItemContent,[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])],1)}),1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }