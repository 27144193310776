import {Roles} from "@/enum/roles";
import {TaskAction} from "@/enum/task_action";
import {TaskItemsCardType} from "@/enum/task_items_card_type";

const filterIcons = {
    number: '$number',
    text: '$text',
    datetime: '$datetime',
    bool: '$boolean'
};

const actionIcons = {
    [TaskAction.ASSIGN]: '$assignTask',
    [TaskAction.START_PROCESSING]: '$startProcessing',
    [TaskAction.APPROVE]: '$approve',
    [TaskAction.FINISH]: '$finish',
    handOver: '$handOver',
    [TaskAction.REJECT]: '$reject',
    [TaskAction.CANCEL]: '$cancel',
    [TaskAction.UNASSIGN]: '$unassign',
    undefined: '$undefined'
};

const roleIcons = {
    [Roles.STOREKEEPER]: '$storeKeeper',
    [Roles.PACKER]: '$packer',
    [Roles.CHIEF]: '$chief',
    [Roles.ORGANIZER]: '$organizer',
    [Roles.ESHOP]: '$eshop',
};

const taskItemsCardTypeIcons = {
    [TaskItemsCardType.TO_MOVE]: 'assignment_late',
    [TaskItemsCardType.IN_INVENTORY]: 'airline_stops',
    [TaskItemsCardType.MOVED]: 'assignment_turned_in',
    [TaskItemsCardType.PRESENT]: 'location_on',
    [TaskItemsCardType.ASSIGNMENT]: 'assignment',
};

const iconAliases = {
    login: 'vpn_key',
    continueAsSuggestedUser: 'how_to_reg',
    loginAsSomebodyElse: 'swap_horiz',
    about: 'tips_and_updates',

    goBack: 'arrow_back',
    goForward: 'arrow_forward',
    goUp: 'subdirectory_arrow_left',
    navigationDivider: 'chevron_right',
    rightMenu: 'more_vert',
    search: 'search',
    info: 'info',
    error: 'error',
    success: 'done',
    waiting: 'hourglass_top',
    check: 'check',

    domain: 'dns',
    manageUser: 'person',
    logout: 'meeting_room',
    manageCache: 'data_usage',
    version: 'account_tree',

    cacheUser: 'person',
    cacheDelete: 'delete_sweep',

    lightTheme: 'light_mode',
    darkTheme: 'dark_mode',
    systemTheme: 'computer',

    nothing: 'block',
    hint: 'info',
    cancelLoadingHint: 'warning',

    moveItemUp: 'expand_less',
    moveItemDown: 'expand_more',
    moveItemLeft: 'chevron_left',
    moveItemRight: 'chevron_right',
    saveItem: 'save',
    addItem: 'add',
    updateItem: 'edit',
    printItem: 'print',
    itemName: 'label',
    closeItem: 'close',
    removeItem: 'clear',
    deleteItem: 'close',
    deleteItemForever: 'delete_forever',
    deleteTableItem: 'delete',
    showItem: 'visibility',
    openItem: 'open_in_new',
    hideItem: 'visibility_off',
    canBeFoundAt: 'not_listed_location',
    pickedUpFrom: 'turn_slight_right',
    canBePutOn: 'where_to_vote',
    alreadyPlaced: 'add_location_alt',
    itemsSelected: 'done_outline',
    removeSelection: 'remove_done',

    copyToClipboard: 'content_copy',
    description: 'notes',
    attachment: 'attach_file',
    quantity: 'repeat_one',

    extendedFilter: 'filter_list',
    filter: 'filter_alt',
    splitCondition: 'call_split',
    export: 'save_alt',
    import: 'file_upload',

    homepageConfig: 'settings',
    deleteDashboardItem: 'close',
    restoreDefaultItems: 'restore',
    taskListAutoRefresh: 'cached',
    reloadData: 'cached',
    allTasksDone: 'check_circle',
    removeSort: 'close',

    showMore: 'expand_more',
    showLess: 'expand_less',

    datePick: 'date_range',
    timePick: 'access_time',
    time: 'access_time',

    storeKeeper: 'directions_walk',
    packer: 'view_in_ar',
    chief: 'supervisor_account',
    organizer: 'transfer_within_a_station',
    eshop: 'shopping_cart',

    task: 'assignment',

    taskDeliveryAccept: 'local_shipping',
    taskDeliveryAcceptSubmit: 'flight_land',

    taskStockLoading: 'archive',
    taskStrictMode: 'settings_suggest',
    multiplyPrices: 'price_check',

    taskStockTaking: 'gavel',
    restrictType: 'widgets',
    taskStockTakingLocations: 'dynamic_feed',
    allowFilledLocations: 'developer_board',

    taskStockPicking: 'call_made',
    taskStockPickingShippingType: 'airport_shuttle',
    taskStockPickingSet: 'dashboard_customize',
    taskMoveProducts: 'redo',
    taskLocationTransfer: 'move_down',
    taskSubstockTransfer: 'sync_alt',

    taskPreparePackage: 'view_in_ar',
    package: 'view_in_ar',
    removePackage: 'remove',
    toBePacked: 'redeem',

    taskExternalOrder: 'shopping_cart',
    specificBuyerContact: 'contact_phone',
    taskExternalOrderProcessingMode: 'precision_manufacturing',
    active: 'star',

    taskProductsReservation: 'book',

    movementType: 'timeline',
    PICK: 'north_east',
    PUT: 'south_east',
    locationType: 'category',
    USER: 'person',
    STOCK: 'warehouse',
    locationTransferMove: 'east',

    assignTask: 'person_add',
    startProcessing: 'label_important',
    taskSelect: 'category',
    taskAttachmentDownloaded: 'done',
    taskNote: 'insert_comment',
    taskPriority: 'priority_high',
    taskSource: 'trending_up',
    taskDestination: 'trending_down',
    taskReport: 'file_copy',

    itemsToMove: 'shopping_basket',

    deliveryType: 'airport_shuttle',
    deliveryNumber: 'note_add',
    invoiceNumber: 'receipt',

    scannerIdle: 'settings_remote',
    scannerSource: 'logout',
    scannerDestination: "login",
    scannerOrder: 'receipt_long',

    itemQuantityChange: 'dialpad',
    itemReturn: 'undo',
    itemReturnConfirmation: 'open_in_new',
    itemChangeTargetLocation: 'place',

    invoice: 'receipt_long',
    deliveryNote: 'receipt_long',

    approve: 'done',
    finish: 'how_to_reg',
    handOver: 'local_shipping',
    reject: 'thumb_down',
    cancel: 'block',
    unassign: 'person_remove',
    undefined: 'contact_support',

    product: 'shopping_basket',
    productInstance: 'local_offer',
    addProductInstance: 'add_shopping_cart',
    productInstanceType: 'inventory_2',
    productModel: 'ballot',
    productType: 'category',
    productMeasureUnit: 'straighten',
    productVisible: 'power_settings_new',
    productSuggestedBuyPrice: 'call_received',
    productSuggestedSellPrice: 'call_made',
    productPriceVat: 'subdirectory_arrow_right',

    addAttribute: 'art_track',
    attributeKey: 'vpn_key',
    attributeValue: 'comment',

    productBatch: 'view_carousel',
    addBatch: 'shuffle',
    batchCode: 'vpn_key',
    manufacturedDate: 'shopping_cart',
    expiryDate: 'remove_shopping_cart',

    addImage: 'add_a_photo',
    image: 'photo_camera',
    addFoilImage: 'add_photo_alternate',
    foilImage: 'image',
    imageLink: 'link',

    productSerialNumber: 'closed_caption',
    serialNumber: 'strikethrough_s',

    barcode: 'line_weight',
    addBarcode: 'qr_code_2',
    barcodeDescription: 'label',
    barcodePrintQuantity: 'local_printshop',
    barcodePrintFormat: 'format_size',
    barcodeList: 'reorder',

    stock: 'store',
    stockSmall: 'photo_size_select_small',
    substock: 'dashboard',
    preparePackageMode: 'view_in_ar',
    orderWithReservation: 'production_quantity_limits',
    autoMergeStockPickings: 'merge',
    allowMultipleShipmentLabelPrints: 'repeat',
    markAsDeliveredAfterPacking: 'fact_check',
    addSubstock: 'dashboard_customize',
    automaticDemandsSatisfaction: 'flight_takeoff',
    location: 'location_on',
    addLocation: 'add_location_alt',
    newLocation: 'tab',
    availability: 'accessible',
    expeditionLocation: 'redeem',
    mobileLocation: 'shopping_cart',
    batchAddToWhitelist: 'task_alt',
    batchRemoveFromWhitelist: 'highlight_off',

    locationLock: 'lock',
    strictLocationLock: 'key',

    maxAutomaticSets: 'menu_open',
    minOrdersPerAutomaticSet: 'close_fullscreen',
    maxOrdersPerAutomaticSet: 'open_in_full',
    enableAutomaticTriggering: 'auto_fix_high',

    stockStatus: 'home_work',
    stockLocationsStatus: 'blinds_closed',
    stockMovements: 'swap_horiz',

    imports: 'cloud_upload',
    exports: 'cloud_download',

    shipping: 'flight_takeoff',
    shippingReport: 'airplane_ticket',
    shippingHandover: 'send',
    shipmentSelect: 'checklist',
    issueProtocol: 'post_add',
    track: 'gps_fixed',
    returning: 'keyboard_return',

    aresValidation: 'text_rotate_vertical',
    aresValidated: 'spellcheck',
    aresNonValidated: 'clear',

    ico: 'perm_identity',
    dic: 'perm_identity',
    phone: 'local_phone',
    email: 'mail_outline',
    website: 'language',

    owners: 'groups',
    owner: 'perm_identity',

    manufacturer: 'business',
    manufacturerAbbreviation: 'vertical_align_center',
    addManufacturer: 'add_business',

    supplier: 'local_shipping',
    carrier: 'local_airport',
    carrierName: 'label',
    carrierActive: 'power_settings_new',
    carrierType: 'garage',
    carrierParameters: 'article',
    carrierService: 'shopping_bag',
    carrierServiceName: 'label',
    carrierServiceParameters: 'settings',
    carrierWriteOnlyParameter: 'disabled_visible',

    buyers: 'contacts',
    buyer: 'perm_identity',
    buyerType: 'category',
    buyerAddressPrivacy: 'security',
    buyerAddressDefault: 'keyboard_return',
    addBuyerAddress: 'add_home_work',

    users: 'mood',
    addUser: 'person_add',
    userActive: 'power_settings_new',
    userFirstName: 'filter_1',
    userLastName: 'filter_2',
    username: 'exit_to_app',
    userEmail: 'email',
    userPhone: 'phone',
    password: 'password',

    address: 'location_city',
    suburb: 'domain',
    street: 'map',
    houseNumber: 'local_convenience_store',
    city: 'location_city',
    postalCode: 'near_me',
    country: 'terrain',

    fullNumber: 'account_balance',
    iban: 'terrain',

    // StockMap icons
    mapEditor: 'map',
    rack: 'calendar_view_month',
    obstacle: 'remove_circle',
    zone: 'texture',
    locationDimension: 'ruler-square',
    locationGroup: 'place',
    moveObject: 'open_with',
    rotateObject: 'pivot_table_chart',
    copyObject: 'content_copy',
    deleteObject: 'delete',
    editName: 'edit',
    edit: 'edit',
    rackDetail: 'manage_search',
    dimensions: 'straighten',
    generalSettings: 'settings',
    dimensionColorIcon: 'circle',
    close: 'close',
    tick: 'done',
    heatMap: 'query_stats',
    zoomIn: 'zoom_in',
    zoomOut: 'zoom_out',

    number: 'tag',
    float: 'percent',
    text: 'format_quote',
    datetime: 'calendar_month',
    boolean: 'toggle_off',

    addUserAlt: 'person_add_alt_1',
    datePicker: 'edit_calendar',

    graph: "query_stats",

    swift: 'business',

    primaryKeyOff: 'key_off',
    primaryKey: 'key',

    tableRow: 'table_rows',
    tableColumn: 'view_column',
    tableHeader: 'web_asset',
    tableFileType: 'table_view',

    buyPrice : 'payments',
    continue: 'fast_forward',

    changedParametersWarn: 'report_problem',

    unmap: 'cancel',

    attributeTemplate: 'extension',
};

export {filterIcons, actionIcons, roleIcons, taskItemsCardTypeIcons, iconAliases};
