<template>
  <v-list-item>
    <v-btn-toggle
      v-model="lang"
      mandatory
      class="mr-3"
    >
      <v-btn
        v-for="langName of langNames"
        :key="langName"
        text
      >
        {{ langName.toUpperCase() }}
      </v-btn>
    </v-btn-toggle>
  </v-list-item>
</template>

<script>

    export default {
        name: "ToolbarChangeLang",
        data: () => ({
            langNames: [
                'en',
                'cs',
                'sk',
                'hu',
                'uk'
            ]
        }),
        computed: {
            lang: {
                get() {
                    const index = this.langNames.indexOf(this.$i18n.locale);
                    return index !== -1 ? index : 0;
                },
                set(newVal, oldVal) {
                    if (oldVal === null) {
                        return;
                    }
                    const newLang = this.langNames[newVal];
                    this.$store.dispatch('userConfig/set', {
                        key: 'lang',
                        value: newLang
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>
