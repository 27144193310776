import { render, staticRenderFns } from "./DashboardTaskListConfig.component.vue?vue&type=template&id=9e30b1de&scoped=true"
import script from "./DashboardTaskListConfig.component.vue?vue&type=script&lang=js"
export * from "./DashboardTaskListConfig.component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e30b1de",
  null
  
)

export default component.exports