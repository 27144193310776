import {PrintType} from "@/enum/print_type";
import {ShipmentState} from "@/enum/shipment_state";
import {APIFilterOP} from "@/service/APIFilters";
import {AttributeDataTypes} from "@/enum/attribute_data_types";

export default {
    and: "a",
    or: "nebo",
    close: "Zavřít",
    undo: "Vrátit",
    open: "Otevřít",
    used: "využito",
    free: "volné",
    reset: "Reset",
    about: "O aplikaci FulWMS",
    loading: "Načítá se",
    cancelLoading: "Zrušit a přejít na přehled",
    cancelLoadingHint: "Načítání se na pozadí může a nemusí dokončit!",
    home: "Domů",
    notFound: "Nenalezeno",
    pageNotFound: "Stránka nenalezena",
    darkMode: "Tmavý režim",
    productLabels: "Zobrazení položek",
    productModel: {
        0: "Název",
        1: "Model"
    },
    showProductImagesInTasks: "Zobrazovat produktové fotky v úkolech",
    pickFromMobileLocation: "Navrhovat sběr položek z mobilního umístění",
    automagicallyChooseInstance: {
        label: "Automaticky vybírat konkrétní položky",
        hint: "Pro tisk štítku, pokud dojde k načtení nesprávné konkrétní položky."
    },
    onBeep: "Při napípnutí",
    search: "Hledat",
    searchAll: "Hledat všude",
    searchHint: "Prohledává {0} a {1}.",
    searchHintSingle: "Prohledává {0}.",
    searchResult: {
        searchesIn: "Prohledává úkoly (číslo, externí id, stav, priorita a typ), skladové položky (výrobce, název a model), sklady (název), osoby (ičo) a čárové kódy.",
        tasksById: "Úkoly",
        tasksByExternalId: "Úkoly s externím id {0}",
        tasksByState: "Úkoly se stavem {0}",
        tasksByPriority: "Úkoly s prioritou {0}",
        tasksByType: "Úkoly typu {0}",
        productsByBarcode: "Čárový kód skladové položky",
        productsByManufacturer: "Skladové položky výrobce {0}",
        productsByName: "Skladové položky s '{0}' ve jméně",
        productsByModel: "Skladové položky s modelem {0}",
        stocksByName: "Sklady s '{0}' ve jméně",
        locationsByBarcode: "Čárový kód umístění",
        buyersByIco: "Odběratel",
        suppliersByIco: "Dodavatel",
        stockOwnersByIco: "Vlastník podskladu"
    },
    advancedFilter: "Pokročilé filtry",
    filterBy: "Filtrovat",
    useHeaderToSearch: "Pro vyhledávání použijte pole v hlavičce tabulky",
    orWriteYourOwn: "nebo začněte psát vlastní",
    noIco: "IČO nevyplněno",
    actions: "Akce",
    nothing: "Nic",
    no: "Ne",
    yes: "Ano",
    of: 'z',
    pcs: 'ks',
    view: "Zobrazit",
    hide: "Skrýt",
    showAll: "Zobrazit všechny",
    hideAll: "Skrýt všechny",
    edit: "Upravit",
    delete: "Smazat",
    confirmDelete: "Opravdu smazat?",
    reload: "Obnovit",
    save: "Uložit",
    saved: "Uloženo",
    notSave: "Neukládat",
    cancel: "Storno",
    active: 'Aktivní',
    clickToCopy: "Zkopírujte kliknutím",
    copyToClipboard: "Zkopírovat do schránky",
    copyToClipboardDone: "'{0}' zkopírováno!",
    copyToClipboardFail: "Kopírování se nezdařilo!",
    goBack: "Zpět na předchozí stránku",
    goUp: "Zpět o úroveň výš",
    lang: "Jazyk",
    help: {
        title: "Nápověda"
    },
    sort: {
        DESC: 'sestupně',
        ASC: 'vzestupně'
    },
    cache: {
        title: "Správa cache",
        contents: "Obsah cache",
        length: "Počet",
        size: "Velikost",
        clear: "Promazat",
        clearAll: "Promazat vše",
        taskDetails: "Detaily úkolu",
        barcodes: "Čárový kód",
        buyers: "Odběratel",
        locations: "Umístění",
        allowedLocationIds: "Povolená umístění skladové položky",
        instances: "Detail skladové položky",
        products: "Skladová položka",
        instanceBarcodes: "Čárové kódy skladové položky",
        instanceTypes: "Speciální instance",
        users: "Uživatel"
    },
    login: "Přihlásit se",
    continueAs: "Pokračovat jako",
    loginOther: "Přihlásit někoho jiného",
    logout: "Odhlásit se",
    version: "Verze",
    offline: "Bez připojení k serveru!",
    readonly: "Aplikace je v režimu pouze pro čtení!",
    download: "Stáhnout {0}",
    uploadAttachment: "Nahrát přílohu",
    filter: {
        [APIFilterOP.AND]: 'a zároveň',
        [APIFilterOP.OR]: 'a nebo',
        [APIFilterOP.IS_NULL]: '{0} je prázdné',
        [APIFilterOP.IS_NOT_NULL]: '{0} není prázdné',
        [APIFilterOP.ARRAY_CONTAINS]: 'seznam {0} obsahuje {1}',
        [APIFilterOP.ARRAY_NOT_CONTAINS]: 'seznam {0} neobsahuje {1}',
        [APIFilterOP.ARRAY_EMPTY]: 'seznam {0} je prázdný',
        [APIFilterOP.ARRAY_NOT_EMPTY]: 'seznam {0} není prázdný',
        [APIFilterOP.LIKE]: '{0} obsahuje {1}',
        [APIFilterOP.NOT_LIKE]: '{0} neobsahuje {1}',
        [APIFilterOP.EQUALS]: '{0} je {1}',
        [APIFilterOP.NOT_EQUALS]: '{0} není {1}',
        [APIFilterOP.GREATER_THAN]: '{0} je větší než {1}',
        [APIFilterOP.LOWER_THAN]: '{0} je menší než {1}',
        [APIFilterOP.GREATER_THAN_OR_EQUAL]: '{0} je větší nebo rovno než {1}',
        [APIFilterOP.LOWER_THAN_OR_EQUAL]: '{0} je menší nebo rovno než {1}',
        [APIFilterOP.BETWEEN]: '{0} je mezi {1} a {2}',
        [APIFilterOP.IN]: '{0} je jedno z: {1}',
        [APIFilterOP.NOT_IN]: '{0} není žádné z: {1}',
    },
    filterConfig: {
        filter: "Filtr",
        invalid: "Filtr není validní!",
        sort: "Řazení",
        condition: "Podmínka",
        addCondition: "Přidat podmínku",
        splitCondition: "Rozvětvit podmínku",
        removeCondition: "Smazat podmínku",
        nestedGroup: "Vnořené podmínky",
        nonNested: "Přímé podmínky",
        addFilter: "Přidat filtr",
        addSort: "Přidat řazení",
        removeSort: "Smazat řazení",
        sortThen: "pak podle",
        fill: "Vyplňte",
        fillFrom: "Vyplňte od",
        fillTo: "Vyplňte do",
        attributeChanged: "Atribut změněn z {0} na {1}.",
        booleanValues: {
            true: "Pravda",
            false: "Nepravda"
        },
        select: "Vyberte",
        fromInputError: "Hodnota musí být nižší nebo rovna {0}",
        toInputError: "Hodnota musí být vyšší nebo rovna {0}",
    },
    regex: {
        '^': 'Začátek',
        '$': 'Konec',
        dot: 'Libovolný znak',
        '?': 'Žádný nebo jeden výskyt',
        '*': 'Žádný nebo libovolný počet výskytů',
        '+': 'Jeden nebo více výskytů',
        '\\': 'Následující znak není speciální znak',
        '()': 'Ohraničení skupiny znaků',
        squareBrackets: 'Vyhledávání konkrétního znaku ze skupiny',
        '{}': 'Specifikace konkrétního počtu opakování',
    },
    table: {
        batchActions: "Hromadné akce",
        removeSelection: "Odstranit výběr",
        batchActionDisabledByFulltext: "Nelze kombinovat s fulltext hledáním. Použijte pokročilé filtry.",
        batchAllFiltered: "Pro všechny vyfiltrované",
        batchAllSelected: "Pro všechny označené",
        filter: {
            chooseAttr: '' // intentionally blank
        }
    },
    reports: {
        title: "Reporty",
        domain: "Oblast reportu",
        type: "Typ reportu",
        new_location: "Tento report nově naleznete v záložce 'Reporty'.",
        redirect: "Přejít"
    },
    shipping: {
        link: 'Zásilky',
        title: 'Zásilky',
        optional: "Volitelné filtry",
        table: {
            state: "Stav",
            external_order_external_order_id: 'Ext. č. obj.',
            external_order_id: "Objednávka",
            stock_picking_id: "Vyskladnění",
            buyer: {
                id: "Odběratel",
                name: "Odběratel",
                email: "E-mail",
                phone: "Telefon"
            },
            subordinate_stock_id: "Podsklad",
            carrier_id: "Dopravce",
            last_validated: "Zkontrolována",
            carrier_api_error_messages: "Chyby dopravce",
            external_order_created_at: 'Objednávka vytvořena',
            created_at: 'Zásilka vytvořena',
            label_first_printed_at: "Štítek poprvé vytištěn",
            last_protocol_created_at: "Poslední protokol vytvořen",
            sent_date: "Odesláno",
            total_value: "Cena",
            total_weight: "Váha",
            package_count: "Počet balíků",
            searchHint: "Prohledává dopravce, odběratele, e-mail, telefon a externí číslo objednávky"
        },
        shipment: {
            show: "Zobrazit zásilku",
            printLabel: "Vytisknout štítek zásilky",
            printDetail: {
                label: "Vytisknout předávací protokol",
                hint: "Nejdříve vyfiltrujte právě jeden podsklad a právě jednoho dopravce."
            },
            track: "Sledovat zásilku",
            state: {
                [ShipmentState.CREATED]: "Nová",
                [ShipmentState.WAITING_FOR_PICKUP]: "Čeká na odeslání",
                [ShipmentState.SENT]: "Odeslána",
                [ShipmentState.BEING_DELIVERED]: "Doručována",
                [ShipmentState.DELIVERED_TO_PICKUP_LOCATION]: "Doručena na výdejní místo",
                [ShipmentState.DELIVERED]: "Doručena",
                [ShipmentState.RETURNING]: "Vrací se",
                [ShipmentState.RETURNED]: "Vrácená",
                [ShipmentState.LOST]: "Ztracená",
                [ShipmentState.TO_BE_CANCELLED]: "Ruší se",
                [ShipmentState.CANCELLED]: "Zrušená",
                [ShipmentState.UNKNOWN]: "Neznámý",
            }
        },
        report: {
            link: 'Report zásilek',
            title: 'Report zásilek',
            table: {
                external_order_task_id: 'Objednávka',
                external_order_states: 'Stav objednávky',
                order_id: 'Externí číslo',
                buyer_name: 'Odběratel',
                shipment_state: 'Stav zásilky',
                eshop_created_at: 'Přijata eshopem',
                wms_created_at: 'Přijata skladem',
                work_started_at: 'Začátek práce',
                work_ended_at: 'Konec práce',
                carrier_accepted_at: 'Přijata dopravcem',
                carrier_delivered_at: 'Doručena',
                searchHint: "Prohledává číslo objednávky, externí číslo a odběratele"
            }
        },
        handover: {
            link: 'Předávání zásilek',
            title: 'Předávání zásilek',

            form: {
                carrierId: "Zvolte dopravce",
                stockId: "Zvolte sklad",
                subStockId: "Pouze podsklad ...",
                selectShipments: "Vybrat zásilky",
            },

            table: {
                created_at: "Datum vytvoření",
                substock: {
                    id: "Podsklad"
                },
                shipment: {
                    ids: "Počet zásilek",
                    detail: "Detail"
                },
                issueProtocol: "Předat zásilky",
                notYetIssued: "Ještě nevystaven"
            },

            handOverAll: "Předat všechny zásilky ({0})",
            reload: "Aktualizovat čekající zásilky",
            waitingForPacking: "Zásilky čekající na balení: {0}",

            create: {
                title: "Vystavit individuální protokol",

                form: {
                    carrierId: "Zvolte dopravce",
                    stockId: "Zvolte sklad",
                    subStockId: "Zvolte podsklad",
                    selectShipments: "Vybrat zásilky",
                    noShipmentSelect: "Jinak vytiskne protokol pro všechny zásilky, které čekají na předání",
                },
            },

            printProtocol: "Vytisknout protokol",
            noStockShipmentsWaiting: "Žádné zásilky z vybraného skladu nečekají na předání vybranému dopravci.",
            noShipmentsWithoutProtocol: "Neexistuje žádná zásilka pro předání, která by již nebyla obsažena v protokolu." +
                "Pro vytvoření dalšího protokolu pro takovou zásilku, je třeba ji explicitině vybrat."
        }
    },
    images: {
        name: "Obrázek",
        plural: "Obrázky",
        url: "Adresa",
        main: "Hlavní obrázek",
        setMain: "Nastavit jako hlavní",
        type: {
            name: "Typ",
            local: "místní",
            localLabel: "Nahrát obrázek",
            external: "externí",
            externalLabel: "Vložit obrázek z URL"
        },
        create: {
            titleSimple: "Přidat obrázek",
            title: "Přidat obrázek k '{0}'",
            done: "Obrázek přidán k '{0}'",
            unableToLoad: "Obrázek se nepodařilo načíst"
        },
        update: {
            titleSimple: "Upravit obrázek",
            title: "Upravit obrázek '{0}'",
            done: "Obrázek '{0}' upraven"
        },
        delete: {
            done: "Obrázek odebrán",
            failed: "Obrázek se nepodařilo odebrat. Zkuste to prosím znovu později."
        },
        store: {
            title: "Uložit lokálně",
            hint_keep_link: "Bude uložen pouze odkaz na obrázek",
            hint_store_link: "Obrázek bude při uložení stažen a uložen lokálně",
            done: "Externí obrázek byl úspěšně stažen a uložen jako místní"
        }
    },
    print: {
        sent: "Odesláno na tiskárnu!",
        noConfigFor: "Není nakonfigurován tisk pro {0}!",
        socketTimeout: "Chyba při připojení k tiskárně, zkontrolujte prosím konfiguraci.",
        error: 'Něco se během tisku pokazilo, zkontrolujte prosím výsledek tisku.'
    },
    list: {
        loadMore: "Načíst další",
        loadingMore: "Načítám další",
        unableToLoad: "Nepodařilo se načíst seznam. Zkuste to prosím znovu."
    },
    api: {
        401: "Uživatel není přihlášen!",
        403: "K této funkci nemáte přístup!",
        405: "Tato akce nyní není povolena!",
        412: "Položka byla v mezičase změněna jiným uživatelem, Proveďte prosím akci znovu.",
        unexpectedError: "Nastala neočekávaná chyba. Zkuste prosím akci opakovat později.",
        ares: {
            status: {
                404: "Subjekt se zadaným IČO nebyl nalezen!"
            }
        },
        barcodes: {
            unknown: "Neznámý kód",
            status: {
                404: "Zadaný kód nenalezen!"
            }
        },
        shipments: {
            packages: {
                status: {
                    404: "Zásilka nebo balíček nenalezen!",
                    409: "Balíček nelze odebrat - zásilka už byla odeslána!"
                }
            },
            protocols: {
                create: {
                    status: {
                        404: "Dopravce, podsklad nebo jedna ze zásilek nenalezena!",
                        409: "Některá ze zásilek není ve stavu 'Čeká na odeslání' nebo nemá přiřazeno doručovací číslo."
                    }
                },
                getAll: {
                    status: {
                        404: "Nasmlouvaný dopravce nebo podsklad nenalezen!"
                    }
                },
                status: {
                    404: "Požadovaný protokol nenalezen!",
                    423: "Požadovaný protokol ještě není připravený, zkuste prosím akci později.",
                }
            },
            status: {
                404: "Požadovaná zásilka nenalezena!",
                423: "Štítek dopravce ještě není připravený, zkuste prosím akci později.",
                409: "Zásilka není ve stavu, ve kterém by šla odeslat!"
            }
        },
        shipmentCarrier: {
            contracted: {
                all: {
                    byOwner: {
                        status: {
                            404: "Vlastník podskladu nenalezen!"
                        }
                    },
                    bySubStock: {
                        status: {
                            404: "Podsklad nenalezen!"
                        }
                    }
                },

                status: {
                    404: "Dopravce nebo vlastník podskladu nenalezen!",
                    409: "Dopravce se nepodařilo odstranit ze seznamu nasmlouvaných dopravců."
                }
            },

            status: {
                404: "Požadovaný dopravce nenalezen!"
            }
        }
    },
    address: {
        street: "Ulice",
        house_number: "Číslo popisné",
        suburb: "Upřesnění adresy",
        city: "Město",
        postal_code: "PSČ",
        phone: "Telefon",
        email: "E-mail",
        country: "Země (lokalizovaně)",
        country_iso_code: "ISO Země (anglicky)",
    },
    bank_account: {
        prefix_number: "Prefix",
        account_number: "Číslo účtu",
        bank_code: "Kód banky",
        full_number: "Číslo účtu vč. kódu banky",
        iban: "IBAN",
        swift: "SWIFT"
    },
    changes: {
        created_at: "Vytvořeno",
        created_by: "Vytvořil",
        updated_at: "Poslední úprava",
        updated_by: "Upravil",
        noUpdates: "Žádné následné úpravy"
    },
    ares: {
        load: "Načíst data z ARESu",
        validated: "Ověřeno z ARESu",
        not_validated: "Není ověřeno z ARESu"
    },
    print_type: {
        [PrintType.PDF]: "PDF",
        [PrintType.ZPL]: "ZPL",
        [PrintType.ZPLX]: "ZPLX",
        [PrintType.ZPLX2]: "ZPLX2",
        [PrintType.ZPL_PD]: "ZPLPD",
        [PrintType.ESCP]: "ESCP",
        [PrintType.HTML]: "HTML"
    },
    form: {
        rules: {
            mustBeHigherThanFrom: "Nesmí být dříve než 'od'!",
            mustBeUpToToday: "Nesmí být později než dnes!",
            maxDays: "Maximálně {0} dnů!"
        }
    },
    attributes: {
        dataTypes: {
            [AttributeDataTypes.STRING]: 'Text',
            [AttributeDataTypes.INTEGER]: 'Celé číslo',
            [AttributeDataTypes.FLOAT]: 'Desetinné číslo',
            [AttributeDataTypes.BOOLEAN]: 'Pravdivostní hodnota',
        },
        templates: {
            title: "Atributy",
            titleSingle: "Atribut",
            link: "Atributy",
            create: "Vytvořit atribut",
            update: {
                link: "Upravit atribut",
                title: "Upravit {0}"
            },
            delete: {
                usage: {
                  loading: "Probíhá načítání použití atributu '{0}'",
                  inUse: "Atribut '{0}' se používá a jeho smazáním dojde ke ztrátě hodnot",
                  notInUse: "Atribut '{0}' se nepoužívá"
                },
                done: "Atribut {0} smazán",
            },
            form: {
                description: 'Popis',
                value: "Název",
                data_type: 'Datový typ'
            }
        }
    },
};
