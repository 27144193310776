<template>
  <v-list-item to="/about">
    <v-icon class="mr-3">
      $about
    </v-icon>
    <span>
      {{ $t('base.about') }}
    </span>
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarAbout",
    };
</script>

<style scoped>

</style>
