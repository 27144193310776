<template>
  <div>
    <v-app-bar
      class="elevation-0"
      style="cursor: pointer"
      @click="clickLogo"
    >
      <img
        alt="FulWMS logo"
        :src="logoSrc"
        :width="$vuetify.breakpoint.mdAndUp ? '160px' : '140px'"
      >
    </v-app-bar>
    <div>
      <Search class="ma-3" />
      <v-divider />
    </div>
    <div
      v-for="(section, index) of filteredItems"
      :key="index"
      class="mb-2"
    >
      <v-divider v-if="index !== 0" />
      <v-subheader>
        {{ $t(section.header) }}
        <v-spacer />
      </v-subheader>
      <v-list>
        <v-list-item
          v-for="item of section.items"
          :key="item.to"
          :class="item.submenu ? 'text-body-2 ml-4 mt-n2' : ''"
          :style="item.submenu ? '' : 'z-index: 5'"
          :to="item.to"
          exact
        >
          <v-list-item-action>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t(item.label) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
    import Search from "@/app/components/search/Search.component";
    import {drawerMenu} from "@/app/components/DrawerMenu/DrawerMenuDefinition";

    export default {
        name: "DrawerMenu",
        components: {Search},
        data: () => ({
            sections: [],
        }),
        computed: {
            filteredItems: function () {
                return this.sections.map(section => ({
                    header: section.header,
                    items: section.items.filter(item => item.show)
                })).filter(section => section.items.some(item => item.show));
            },
            logoSrc: function () {
                return process.env.BASE_URL + 'logo/fulwms-logo' + (this.$vuetify.theme.dark ? '-white' : '') + '.svg';
            }
        },
        createdOrActivated() {
            this.sections = drawerMenu();
        },
        methods: {
            clickLogo: function () {
                if (this.$route.fullPath !== '/') {
                    this.$router.push('/');
                }
            }
        }
    };
</script>

<style scoped>

</style>
