<template>
  <div>
    <x-toolbar />
    <div
      class="d-flex align-center justify-center flex-column"
      style="height: 70vh"
    >
      <a
        href="https://jagu.cz"
        target="_blank"
      >
        <v-img
          alt="FulWMS logo"
          :src="logoSrc"
          width="400px"
          max-width="80vw"
        />
      </a>
      <span
        class="text--disabled mt-15"
      >
        {{ `${domain} @ ${version}` }}
      </span>
    </div>
  </div>
</template>

<script>
    import {config} from "@/config/config";

    export default {
        name: "About",
        data: () => ({
            version: config.APP_VERSION,
            domain: config.DOMAIN_LABEL,
        }),
        computed: {
            logoSrc: function () {
                return process.env.BASE_URL + 'logo/fulwms-logo' + (this.$vuetify.theme.dark ? '-white' : '') + '-byjagu.svg';
            }
        }
    };
</script>
