<template>
  <span>
    {{ domain }}
  </span>
</template>

<script>
    import {config} from "@/config/config";

    export default {
        name: "ToolbarDomain",
        data: () => ({
            domain: config.DOMAIN_LABEL
        })
    };
</script>

<style>

</style>
