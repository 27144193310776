import {config} from "@/config/config";
import {has} from "@/utils/object";

/**
 * @param address {Object}
 * @returns {string}
 */
export function addressInline(address) {
    let inline = '';
    const hiddenFields = ['address_id', 'default', 'privacy'];
    for (const item in address) {
        if (!hiddenFields.includes(item) && has(address, item) && address[item] !== null) {
            inline += address[item] + ', ';
        }
    }
    return inline === '' ? '' : inline.slice(0, -2);
}

/**
 * @param person {Object}
 * @returns {string}
 */
export function personInLine(person) {
    let inline = '';
    const displayedFields = ['name', 'ico', 'dic', 'phone', 'email', 'website'];
    for (const field of displayedFields) {
        if (has(person, field) && person[field] !== null) {
            inline += person[field] + ', ';
        }
    }
    return inline === '' ? '' : inline.slice(0, -2);
}

/**
 * @param localisedTitle {string=}
 * @returns {string}
 */
export function tabTitle(localisedTitle) {
    return (localisedTitle ? (localisedTitle + ' | ') : '') + config.DOMAIN_LABEL + ' | FulWMS';
}

/**
 * @param location {{
 *     code: string,
 *     name: string
 * }}
 * @param placeholder
 */
export function locationLabel(location, placeholder = '...') {
    // loading string from cache
    if (typeof location === 'string') {
        return location;
    }
    if (has(location, 'user')) {
        return location.user.first_name + ' ' + location.user.last_name;
    }
    if (location === null || location === undefined) {
        return placeholder;
    } else {
        if (location.code === location.name) {
            return location.code;
        } else {
            return location.code + ' (' + location.name + ')';
        }
    }
}

/**
 * @param text
 * @return {Promise}
 */
export function copyToClipboard(text) {
    return new Promise((resolve, reject) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(resolve).catch(reject);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy') ? resolve() : reject();
            } catch (err) {
                reject(err);
            } finally {
                document.body.removeChild(textArea);
            }
        }
    });
}

/**
* @param pathString = path to object value ( can include arrays ) ex: 'response.data.items[2].id'
*/
export function parseObjectPathString(pathString) {
    return [...pathString.matchAll(/[\w-_]+/g)].map(item=> item[0]);
}

/**
 * @param string {string}
 * @param forcedLength {number} Cut or repeat the string to reach the required length
 * @returns {ArrayBuffer}
 */
export function stringToArrayBuffer(string, forcedLength = null) {
    const length = string.length;
    const buffer = new ArrayBuffer(forcedLength || length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < (forcedLength || length); i++) {
        bufferView[i] = string.charCodeAt(i % length);
    }
    return buffer;
}

/**
 * @param arraybuffer {ArrayBuffer}
 * @returns {string}
 */
export function arrayBufferToString(arraybuffer) {
    let string = '';
    const bytes = new Uint8Array(arraybuffer);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
        string += String.fromCharCode(bytes[i]);
    }
    return string;
}
