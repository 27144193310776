import About from "@/app/About.view";

const BASE_URL = '/about';

const routes = {
    base: {
        path: BASE_URL,
        component: About,
        meta: {
            title: 'base.about',
            getParent: () => '/'
        }
    }
};

export default Object.values(routes);
