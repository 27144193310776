<template>
  <div>
    <v-list-item v-show="$vuetify.breakpoint.xsOnly">
      <v-icon class="mr-3">
        $domain
      </v-icon>
      <ToolbarDomain />
    </v-list-item>
    <v-list-item
      :to="'/users/' + $store.getters['oauth/getUserId']"
    >
      <v-icon class="mr-3">
        $manageUser
      </v-icon>
      <span>
        {{ $store.getters['oauth/getUserFullName'] }}
      </span>
    </v-list-item>
    <v-list-item
      @click="logout()"
    >
      <v-icon class="mr-3">
        $logout
      </v-icon>
      {{ $t('base.logout') }}
    </v-list-item>
  </div>
</template>

<script>
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import ToolbarDomain from "@/app/components/toolbar/ToolbarDomain.component.vue";

    export default {
        name: "ToolbarUser",
        components: {ToolbarDomain},
        mixins: [ACLMixin],
        methods: {
            logout: function () {
                this.$store.commit('loader/overlay/show');
                this.$store.dispatch('time/stop');
                this.$store.dispatch('oauth/logout');
            }
        }
    };
</script>

<style scoped>

</style>
